<template>
  <b-row>
    <b-col md="6">
      <approval approvalType="substitution" />
    </b-col>
    <b-col md="6">
      <approval approvalType="course" />
    </b-col>
    <b-col cols="12">
      <sessions-list />
    </b-col>
    <b-col md="4">
      <inbox-card />
    </b-col>
    <b-col md="8">
      <analytic />
    </b-col>
    <widgets style="margin-top: -15px" />
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Widgets from '@/views/teacher/dashboard/Widgets.vue'
import InboxCard from './InboxCard.vue'
import Analytic from './Analytic.vue'
import SessionsList from './SessionsList.vue'
import Approval from './Approval.vue'

export default {
  components: {
    Widgets,
    BRow,
    BCol,

    InboxCard,
    Analytic,
    SessionsList,
    Approval,
  },
}
</script>
